<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Reporte de actividades"></header-title>
      <v-row class="mb-5">
        <v-col cols="12" md="3">
          <v-select
            label="Gestión"
            :items="years"
            v-model="options.year_id"
            item-text="gestion"
            item-value="id"
            @change="getActivities"
            hide-details
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" md="4"
          ><v-text-field
            class="mr-1"
            label="Buscar"
            v-model="options.search"
            append-icon="mdi-magnify"
            @input="changeSearch"
            hide-details
            clearable
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="5">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                type="button"
                color="error"
                @click="reportActivityInformation"
                v-bind="attrs"
                v-on="on"
                :disabled="!options.year_id"
                :loading="btn_activity"
              >
                PDF
              </v-btn>
            </template>
            <span>reporte actividades</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="activities"
        :loading="loading"
        :page.sync="options.page"
        :items-per-page="options.itemsPerPage"
        hide-default-footer
        calculate-widths
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{
              (options.page - 1) * options.itemsPerPage +
              (activities.indexOf(item) + 1)
            }}
          </td>
        </template>
        <template v-slot:[`item.date_limit`]="{ item }">
          <td>
            {{ item.fecha_limite | formatdate }}
          </td>
        </template>
        <template v-slot:[`item.files`]="{ item }">
          <td>
            <span v-if="item.plantilla">
              <a
                :href="`${serve}gerencia/plantilla/${item.plantilla}`"
                target="_blank"
                >ver o descargar</a
              >
            </span>
            <span class="grey--text" v-else>sin archivo</span>
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <v-chip
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
              small
              color="green lighten-4"
              >activo</v-chip
            >
            <v-chip v-else small color="red lighten-4">inactivo</v-chip>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-btn
              small
              color="success"
              class="mr-1"
              :loading="btn_loadinf"
              @click="showDialog(item)"
            >
              Información
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  type="button"
                  color="red"
                  dark
                  small
                  @click="reportByActivity(item)"
                  v-bind="attrs"
                  v-on="on"
                  :loading="btn_loadpdf"
                  :disabled="activities.length == 0"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                  PDF
                </v-btn>
              </template>
              <span>por actividad</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
      <div class="v-data-footer mt-2">
        <v-row>
          <v-col cols="12" sm="4">
            <div class="v-data-footer__select">
              Filas por página:
              <v-select
                :items="[10, 20, 30, 50, 100]"
                v-model="options.itemsPerPage"
                @change="changePerPage()"
                hide-details
                class="my-0"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="text-center">
            <v-pagination
              v-model="options.page"
              :length="options.pageCount"
              :total-visible="5"
              @input="changePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-if="idialog"
      v-model="idialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Información</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="red" dark @click="idialog = false"> Cerrar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" sm="8">
            <h4 class="teal--text text--darken-2">Datos de la actividad</h4>
            <v-row class="mt-2">
              <v-col class="py-1" cols="12" sm="12"
                >Tipo de información:
                <span class="grey--text text--darken-2">{{
                  activity.tipo_informacion.descripcion
                }}</span></v-col
              >
              <v-col class="py-1" cols="12" sm="12"
                >Descripción:
                <span class="grey--text text--darken-2">{{
                  activity.descripcion
                }}</span></v-col
              >
              <v-col class="py-1" cols="12" sm="12"
                >Fecha límite:
                <span class="grey--text text--darken-2">{{
                  activity.fecha_limite | formatdate
                }}</span></v-col
              >
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <span
              :class="`text-subtitle-2 ${activity.estado ? '' : 'red--text'}`"
            >
              Actividad
              {{ activity.estado ? "vigente" : "no vigente" }}
            </span>
            <p class="grey--text text--darken-2">
              Revisar los documentos enviados. Para ello puede filtrar por
              ESFM/UA.
            </p>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <div class="mx-4">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Información recibida
          </h4>
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                dense
                outlined
                label="Institución"
                :items="institutions"
                v-model="institution_id"
                item-text="nombre"
                item-value="id"
                @change="getInformation"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                dense
                outlined
                label="Estado"
                :items="estado_informacion"
                v-model="status_id"
                item-text="descripcion"
                item-value="id"
                @change="getInformation"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="5">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-1"
                    type="button"
                    color="default"
                    @click="clearFilter"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                    Quitar
                  </v-btn>
                </template>
                <span>Quitar filtro</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    type="button"
                    color="red"
                    dark
                    @click="reportByESFM"
                    v-bind="attrs"
                    v-on="on"
                    :loading="btn_loadpdf"
                    :disabled="informations.length == 0"
                  >
                    <v-icon>mdi-file-download-outline</v-icon>
                    PDF
                  </v-btn>
                </template>
                <span>Reporte por ESFM/UA</span>
              </v-tooltip> -->
            </v-col>
          </v-row>
        </div>
        <v-row class="ma-1">
          <v-col cols="12">
            <div class="v-data-table theme--light">
              <div class="v-data-table__wrapper">
                <table>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Institución</th>
                      <th class="text-left">Descripción</th>
                      <th class="text-left">Documento</th>
                      <th class="text-left">Fecha de envio</th>
                      <th class="text-left">Remitente</th>
                      <th class="text-left">Estado</th>
                      <th class="text-left">Revisor</th>
                      <th class="text-left">Observación</th>
                    </tr>
                  </thead>
                  <tbody v-if="informations.length > 0">
                    <tr v-for="(item, index) in informations" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ item.institucion ? item.institucion.nombre : "" }}
                      </td>
                      <td>{{ item.descripcion }}</td>
                      <td>
                        <a
                          :href="`${serve}gerencia/informacion/${item.documento}`"
                          target="_blank"
                          >ver o descargar</a
                        >
                      </td>
                      <td>
                        {{ item.fecha_envio | formatfulldate }}
                        <v-chip
                          v-if="
                            new Date(activity.fecha_limite) -
                              new Date(item.fecha_envio) >=
                            0
                          "
                          small
                          color="green lighten-4"
                          >puntual</v-chip
                        >

                        <v-chip v-else small color="red lighten-4"
                          >atrasado</v-chip
                        >
                      </td>
                      <td>{{ item.remitente.nombre_completo }}</td>
                      <td>
                        <v-chip
                          v-if="
                            item.estado_informacion.id == 1 ||
                            item.estado_informacion.id == 2
                          "
                          small
                          color="blue lighten-4"
                        >
                          {{ item.estado_informacion.descripcion }}
                        </v-chip>
                        <v-chip
                          v-else-if="item.estado_informacion.id == 4"
                          small
                          color="green lighten-4"
                        >
                          {{ item.estado_informacion.descripcion }}
                        </v-chip>
                        <v-chip v-else small color="red lighten-4">
                          {{ item.estado_informacion.descripcion }}
                        </v-chip>
                      </td>
                      <td>
                        {{
                          item.revisor ? item.revisor.nombre_completo : "---"
                        }}
                      </td>
                      <td>{{ item.observacion }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="text-center grey--text" colspan="9">
                        No hay datos disponibles
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import PDFService from "../../utils/pdf";
import Service from "../../services/request";
import { mapActions } from "vuex";
export default {
  name: "activity",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      btn_loadinf: false,
      btn_activity: false,
      btn_loadpdf: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Tipo información ", value: "tipo_informacion.descripcion" },
        { text: "Actividad ", value: "descripcion" },
        { text: "Fecha límite ", value: "date_limit" },
        { text: "Plantilla ", value: "files", sortable: false },
        { text: "Estado ", value: "status" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      years: [],
      activities: [],
      informations: [],
      institutions: [],
      estado_informacion: [],
      activity: {
        id: "",
        tipo_informacion: {},
        descripcion: "",
        fecha_limite: "",
      },
      information: {
        id: "",
        estado_id: null,
        observacion: null,
      },
      options: {
        year_id: null,
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      institution_id: "",
      status_id: "",
      idialog: false,
      user: null,
      serve: "",
    };
  },
  computed: {
    blockPermission() {
      return this.informations.reduce(
        (acc, item) => (acc = acc > item.id ? acc : item.id),
        0
      );
    },
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    this.user = Storage.getUser();
    if (this.user && (this.user.rid == 1 || this.user.rid == 3)) {
      this.getParams();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getParams() {
      Service.svcInformationParams("get")
        .then((response) => {
          this.years = response.data[0];
          this.institutions = response.data[1];
          this.estado_informacion = response.data[2];

          const currentYear = new Date().getFullYear();
          const year = this.years.find((item) => item.gestion == currentYear);
          if (year) {
            this.options.year_id = year.id;
          }
          this.getActivities();
        })
        .catch((error) => {
          console.error("No cargo los registros", error);
        });
    },
    getActivities() {
      this.loading = true;
      Service.svcInformationPaginate("post", this.options)
        .then((response) => {
          this.loading = false;
          this.activities = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount = Math.ceil(
              response.data.count / this.options.itemsPerPage
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog(data) {
      this.activity = data;
      this.informations = [];
      this.getInformation();
    },
    getInformation() {
      this.btn_loadinf = true;
      Service.svcInformationReport("post", {
        actividad_id: this.activity.id,
        institucion_id: this.institution_id,
        estado_id: this.status_id,
      })
        .then((response) => {
          this.btn_loadinf = false;
          this.informations = response.data;
          this.idialog = true;
        })
        .catch(() => {
          this.btn_loadinf = false;
        });
    },
    clearFilter() {
      this.institution_id = "";
      this.status_id = "";
      this.informations = [];
      this.getInformation();
    },

    changeSearch() {
      this.getActivities();
    },

    changePerPage() {
      this.options.page = 1;
      this.getActivities();
    },

    changePage() {
      this.getActivities();
    },

    reportActivityInformation() {
      this.btn_activity = true;
      Service.svcActivityInformationReport("post", {
        year_id: this.options.year_id,
      })
        .then((response) => {
          this.btn_activity = false;
          let yearText = "";
          const year = this.years.find(
            (item) => item.id == this.options.year_id
          );
          if (year) {
            yearText = year.gestion;
          }

          PDFService.listActivity(yearText, response.data);
        })
        .catch(() => {
          this.btn_activity = false;
        });
    },

    reportByActivity(activity) {
      this.btn_loadpdf = true;
      Service.svcInformationReport("post", {
        actividad_id: activity.id,
        institucion_id: this.institution_id,
        estado_id: this.status_id,
      })
        .then((response) => {
          this.btn_loadpdf = false;
          PDFService.informationByActivity(activity, response.data);
        })
        .catch(() => {
          this.btn_loadpdf = false;
        });
    },

    /* reportByESFM() {
      console.log(this.institution_id, this.status_id);
    }, */
  },
};
</script>
